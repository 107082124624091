<template>
  <div class="collection">
    <div class="col_center">
      <!-- <h1>我的收藏</h1> -->
      <nav>
        <!-- label-width="80px"  -->
        <el-form ref="resourceRef" :model="resourceForm" :inline="true">
          <el-form-item label="课程名称" prop="courseName" v-if="activeName=='first'">
            <el-input v-model="resourceForm.courseName" @change="querylist" size="mini" clearable></el-input>
          </el-form-item>
          <el-form-item label="系列课程名称" prop="seriseName" v-if="activeName=='second'">
            <el-input v-model="resourceForm.seriseName" @change="querylist" size="mini" clearable></el-input>
          </el-form-item>
          <el-form-item label="资源名称" prop="courseName" v-if="activeName=='third'">
            <el-input v-model="resourceForm.courseName" @change="querylist" size="mini" clearable></el-input>
          </el-form-item>
          <el-form-item label="教研圈名称" prop="circleName" v-if="activeName=='fourth'">
            <el-input v-model="resourceForm.circleName" @change="querylist" size="mini" clearable></el-input>
          </el-form-item>
          <el-form-item label="通知公告名称" prop="acName" v-if="activeName=='fifth'">
            <el-input v-model="resourceForm.acName" @change="querylist" size="mini" clearable></el-input>
          </el-form-item>
          <el-form-item label="帖子标题" prop="projectName" v-if="activeName=='sixth'">
            <el-input v-model="resourceForm.projectName" @change="querylist" size="mini" clearable></el-input>
          </el-form-item>

          <el-form-item
            label="学科类型"
            prop="subjectTypeId"
            v-if="activeName=='first'||activeName=='third'||activeName=='fourth'||activeName=='fifth'"
          >
            <el-select v-model="resourceForm.subjectTypeId" @change="changeshuject" size="mini" clearable>
              <el-option v-for="item in subjectypedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="学科"
            prop="subjectId"
            v-if="activeName=='first'||activeName=='third'||activeName=='fourth'||activeName=='fifth'"
          >
            <el-select v-model="resourceForm.subjectId" size="mini" clearable>
              <el-option v-for="item in subjectdata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="学段"
            prop="periodId"
            v-if="activeName=='first'||activeName=='third'||activeName=='fourth'||activeName=='fifth'"
          >
            <el-select v-model="resourceForm.periodId" @change="changeperiod" size="mini" clearable>
              <el-option v-for="item in perioddata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="年级"
            prop="gradeId"
            v-if="activeName=='first'||activeName=='third'||activeName=='fourth'||activeName=='fifth'"
          >
            <el-select v-model="resourceForm.gradeId" size="mini" clearable>
              <el-option v-for="item in gradedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程标签" prop="labelId" v-if="activeName=='second'">
            <el-select v-model="resourceForm.labelId" size="mini" clearable>
              <el-option v-for="item in seriselabeldata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="公告活动形式" prop="notice" v-if="activeName=='fifth'">
            <el-select v-model="resourceForm.notice" size="mini" clearable>
              <el-option v-for="item in activeTypeData" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属项目" prop="project" v-if="activeName=='sixth'">
            <el-select v-model="resourceForm.project" size="mini" clearable>
              <el-option v-for="item in projectData" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="收藏时间">
            <el-form-item prop="startTime" style="width:150px">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="resourceForm.startTime"
                style="width: 100%;"
                size="mini"
                clearable
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item prop="endTime" style="width:150px">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="resourceForm.endTime"
                style="width: 100%;"
                size="mini"
                clearable
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-form-item>
        </el-form>
        <div class="nav_btns">
          <el-button type="primary" @click="querylist" size="mini">查询</el-button>
          <el-button @click="resetfrom('resourceRef')" size="mini">重置</el-button>
        </div>
      </nav>
      <main>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="普通课程" name="first">
            <div v-if="emptyShow">
              <el-empty description="暂无数据"></el-empty>
            </div>
            <div v-else>
              <div class="pcourse">
                <div v-for="item in listData" :key="item.id">
                  <card :carddata="item" :cardsite="0"></card>
                  <div class="card_footer">
                    <p class="card_one">收藏于：{{item.collcetTime}}</p>
                    <p class="card_two" @click="messageDeleteCollection(item.id,1)">
                      <i class="collcoticon"></i>
                      <span>取消收藏</span>
                    </p>
                  </div>
                </div>
                <div v-for="item in listnum" :key="item" style="width: 299px; height: 270px;"></div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="系列课程" name="second">
            <div v-if="emptyShow">
              <el-empty description="暂无数据"></el-empty>
            </div>
            <div v-else>
              <div class="pcourse">
                <div v-for="item in listDatas" :key="item.id">
                  <cards :carddata="item"></cards>
                  <div class="card_footer">
                    <p class="card_one">收藏于：{{item.collectTime}}</p>
                    <p class="card_two" @click="messageDeleteCollection(item.id,2)">
                      <i class="collcoticon"></i>
                      <span>取消收藏</span>
                    </p>
                  </div>
                </div>
                <div v-for="item in listnums" :key="item" style="width: 299px; height: 270px;"></div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="资源" name="third">
            <div v-if="emptyShow">
              <el-empty description="暂无数据"></el-empty>
            </div>
            <div v-else>
              <div class="pcourse">
                <div v-for="item in listresourData" :key="item.id">
                  <word :carddata="item"></word>
                  <div class="card_footer">
                    <p class="card_one">收藏于：{{item.collcetTime}}</p>
                    <p class="card_two" @click="messageDeleteCollection(item.id,1)">
                      <i class="collcoticon"></i>
                      <span>取消收藏</span>
                    </p>
                  </div>
                </div>
                <div v-for="item in listnumr" :key="item" style="width: 524px; height: 270px;"></div>
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="教研圈" name="fourth">
            <div v-if="emptyShow">
              <el-empty description="暂无数据"></el-empty>
            </div>
            <div v-else>
              <div class="pcourse">
                <div v-for="item in listcircleData" :key="item.id">
                  <writing :carddata="item"></writing>
                  <div class="card_footer">
                    <p class="card_one">收藏于：{{item.collectionTime}}</p>
                    <p class="card_two" @click="messageDeleteCollection(item.id,3)">
                      <i class="collcoticon"></i>
                      <span>取消收藏</span>
                    </p>
                  </div>
                </div>
                <div v-for="item in listnumq" :key="item" style="width: 296px; height: 270px;"></div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="通知公告" name="fifth">
            <div v-if="emptyShow">
              <el-empty description="暂无数据"></el-empty>
            </div>
            <div v-else>
              <div class="pcourse">
                <div v-for="item in listactiveData" :key="item.id">
                  <words :carddata="item"></words>
                  <div class="card_footer">
                    <p class="card_one">收藏于：{{item.collectionTime}}</p>
                    <p class="card_two" @click="messageDeleteCollection(item.id,4)">
                      <i class="collcoticon"></i>
                      <span>取消收藏</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="特色项目" name="sixth">
            <el-table :data="listprojectData" stripe style="width: 100%" height="425px">
              <el-table-column type="index" width="50" align="center" label="序号" :index="computeTableIndex" fixed="left"></el-table-column>
              <el-table-column prop="date" label="帖子标题" width="400" align="center"></el-table-column>
              <el-table-column prop="name" label="所属项目" width="450" align="center"></el-table-column>
              <el-table-column prop="address" label="收藏时间" width="400" align="center"></el-table-column>
              <el-table-column label="操作" width="200" align="center" fixed="right">
                <template slot-scope="scope">
                  <el-button size="mini" type="text" @click="viewdata(scope.row)">查看帖子</el-button>
                  <el-button size="mini" type="text" @click="messageDeleteCollection(scope.row.id,5)" style="color: #EB5757;">取消收藏</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </main>
      <footer>
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="curpage"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </footer>
    </div>
  </div>
</template>
<script>
import card from "../../../../components/card.vue";
import cards from "../../../../components/cards.vue";
import word from "../../../../components/word.vue";
import writing from "../../../../components/writing.vue";
import words from "../../../../components/words.vue";
export default {
  data() {
    return {
      // tabs切换
      activeName: "first",
      // 课程审核列表
      resourceForm: {
        courseName: null, //课程名称 资源名称
        seriseName: null, //系列课程名称
        labelId: null, //系列课程标签
        circleName: null, //教研圈名称
        acName: null, //通知公告
        notice: null, //notice公告活动形式
        projectName: null, //帖子标题
        project: null, //所属项目

        subjectTypeId: null,
        subjectId: null,
        periodId: null,
        gradeId: null,
        startTime: null,
        endTime: null,
      },
      // 系列课程的标签
      seriselabeldata: null,
      //学科类型
      subjectypedata: null,
      // 学科
      subjectdata: null,
      // 学段
      perioddata: null,
      // 年级
      gradedata: null,
      //公告活动形式
      activeTypeData: [
        { id: 1, name: "公告文档" },
        { id: 2, name: "资料收集" },
        { id: 3, name: "投票" },
        { id: 4, name: "调查问卷" },
      ],
      // 所属项目
      projectData: null,
      //数据列表
      emptyShow: false,
      listData: null, //普通课程
      listnum: null,
      listDatas: null, //系列课程
      listnums: null,
      listresourData: null, //资源
      listnumr: null,
      listcircleData: null, //教研圈
      listnumq: null,
      listactiveData: null, //通知公告
      listprojectData: null, //特色项目

      // 分页
      curpage: 1,
      pageSize: 10,
      total: null,
    };
  },
  created() {
    this.queryproject(); //所属项目
    this.querySeriseLable(); //获取系列课程的标签F
    this.querySubjectType(); //获取课程类型
    this.querySubject(0); //获取学科信息
    this.queryPeriod(); //获取学段
    this.queryGrade(0); //获取年级信息
    // 查询普通课程的收藏
    this.queryMyCollectCourse();
    //系列课程
    // this.querySeriseCollectCourse();
    // 资源
    // this.queryMyResource();
    // 教研圈
    // this.queryMyCollectCircle();
    // 通知公告收藏
    // this.querytongzhi();
    // 特色项目收藏
    // this.queryspecial();
  },
  methods: {
    // 查询普通课程的收藏
    async queryMyCollectCourse() {
      const { data, status } = await this.$Http.queryMyCollectCourse({
        // courseName: courseName, //课程名称
        // resourceType: resourceType, //课程类型
        // subjectTypeId: subjectTypeId, //课程类型ID
        // subjectId: subjectId, //学科ID
        // periodId: periodId, //学段ID
        // gradeId: gradeId, //年级ID
        // startTime: startTime, //收藏开始时间
        // endTime:endTime,//收藏结束时间
        ...this.resourceForm,
        pageNo: this.curpage, //页码
        pageSize: this.pageSize, //每页数量
      });
      if (status == 200) {
        // console.log(data, status, "获取普通课程的收藏");
        if (data == null) {
          this.listData = data;
          this.emptyShow = true;
          this.total = 0;
        } else {
          let divisor = Math.trunc(data.total / this.pageSize);
          console.log(divisor, "除数");
          if (divisor < this.curpage) {
            let remainder = data.total % 5;
            console.log(remainder, "取余");
            if (remainder !== 0) {
              this.listnum = 5 - remainder;
            }
          }

          this.listData = data.list;
          this.emptyShow = false;
          this.total = data.total;
        }
      }
    },
    // 获取系列课程的收藏
    async querySeriseCollectCourse() {
      const { data, status } = await this.$Http.querySeriseCollectCourse({
        // seriseName: this.resourceForm.courseName, //课程名称
        // resourceType: resourceType, //课程类型
        // subjectTypeId: subjectTypeId, //课程类型ID
        // subjectId: subjectId, //学科ID
        // periodId: periodId, //学段ID
        // gradeId: gradeId, //年级ID
        // startTime: startTime, //收藏开始时间
        // endTime:endTime,//收藏结束时间
        ...this.resourceForm,
        pageNo: this.curpage, //页码
        pageSize: this.pageSize, //每页数量
      });
      if (status == 200) {
        // console.log(data, status, "获取系列课程的收藏");
        if (data == null) {
          this.listDatas = data;
          this.emptyShow = true;
          this.total = 0;
        } else {
          let divisor = Math.trunc(data.total / this.pageSize);
          console.log(divisor, "除数");
          if (divisor < this.curpage) {
            let remainder = data.total % 5;
            console.log(remainder, "取余");
            if (remainder !== 0) {
              this.listnums = 5 - remainder;
            }
          }

          this.listDatas = data.list;
          this.emptyShow = false;
          this.total = data.total;
        }
      }
    },
    //资源收藏
    async queryMyResource() {
      const { data, status } = await this.$Http.queryMyResource({
        // circleName: circleName, //
        // endTime: endTime, //
        // gradeId: gradeId, //
        // pageNo: pageNo, //
        // pageSize: pageSize, //
        // periodId: periodId, //
        // startTime: startTime, //
        // subjectId: subjectId, //
        // subjectTypeId: subjectTypeId, //
        ...this.resourceForm,
        pageNo: this.curpage, //页码
        pageSize: this.pageSize, //每页数量
      });
      if (status == 200) {
        // console.log(data, status, "资源收藏");
        if (data == null) {
          this.listresourData = data;
          this.emptyShow = true;
          this.total = 0;
        } else {
          let divisor = Math.trunc(data.total / this.pageSize);
          console.log(divisor, "除数");
          if (divisor < this.curpage) {
            let remainder = data.total % 3;
            console.log(remainder, "取余");
            if (remainder !== 0) {
              this.listnumr = 3 - remainder;
            }
          }

          this.listresourData = data.list;
          this.emptyShow = false;
          this.total = data.total;
        }
      }
    },
    //教研圈收藏
    async queryMyCollectCircle() {
      const { data, status } = await this.$Http.queryMyCollectCircle({
        ...this.resourceForm,
        // circleName: circleName, //教研圈名称
        // endTime: endTime, //
        // gradeId: gradeId, //
        pageNo: this.curpage, //页码
        pageSize: this.pageSize, //每页数量
        // periodId: periodId, //
        // startTime: startTime, //
        // subjectId: subjectId, //
        // subjectTypeId: subjectTypeId, //
      });
      if (status == 200) {
        // console.log(data, status, "教研圈收藏");
        if (data == null) {
          this.listcircleData = data;
          this.emptyShow = true;
          this.total = 0;
        } else {
          let divisor = Math.trunc(data.total / this.pageSize);
          console.log(divisor, "除数");
          if (divisor < this.curpage) {
            let remainder = data.total % 5;
            console.log(remainder, "取余");
            if (remainder !== 0) {
              this.listnumq = 5 - remainder;
            }
          }

          this.listcircleData = data.list;
          this.emptyShow = false;
          this.total = data.total;
        }
      }
    },
    // 通知公告收藏
    async querytongzhi() {
      // console.log("通知公告");
      this.listactiveData = null;
      this.emptyShow = true;
      this.total = 0;
    },
    //特色项目收藏
    async queryspecial() {
      // console.log("特色项目");
      this.total = 0;
    },
    //所属项目
    async queryproject() {
      // console.log("所属项目");
      this.projectData = null;
    },
    //获取系列课程的标签
    async querySeriseLable() {
      await this.$Http.querySeriseLable().then((res) => {
        // console.log(res, "获取系列课程的标签");
        if (res.status == 200) {
          this.seriselabeldata = res.data;
        }
      });
    },
    // 获取学科类型
    async querySubjectType() {
      await this.$Http.querySubjectType().then((res) => {
        // console.log(res, "获取课程类型");
        if (res.status == 200) {
          this.subjectypedata = res.data;
        }
      });
    },
    // 获取学科
    async querySubject(id) {
      // console.log(id);
      let ids = [];
      if (id !== 0) {
        ids.push(id);
      }
      const { data, status } = await this.$Http.querySubject({
        idList: ids,
      });
      if (status == 200) {
        // console.log(data, status, "获取学科");
        this.subjectdata = data;
      }
    },
    // 获取学段
    async queryPeriod() {
      await this.$Http.queryPeriod().then((res) => {
        // console.log(res, "获取学段");
        if (res.status == 200) {
          this.perioddata = res.data;
        }
      });
    },
    // 获取年级
    async queryGrade(id) {
      // console.log(id);
      let ids = [];
      if (id !== 0) {
        ids.push(id);
      }
      const { data, status } = await this.$Http.queryGrade({
        idList: ids,
      });
      if (status == 200) {
        // console.log(data, status, "获取年级信息");
        this.gradedata = data;
      }
    },
    // 学科类型的值得变化
    changeshuject(val) {
      this.resourceForm.subjectId = null;
      if (!val) {
        this.querySubject(0);
      } else {
        this.querySubject(val);
      }
    },
    // 学段的值得变化
    changeperiod(val) {
      this.resourceForm.gradeId = null;
      if (!val) {
        this.queryGrade(0);
      } else {
        this.queryGrade(val);
      }
    },
    // 查询
    querylist() {
      if (this.activeName == "first") {
        this.queryMyCollectCourse();
      } else if (this.activeName == "second") {
        this.querySeriseCollectCourse();
      } else if (this.activeName == "third") {
        this.queryMyResource();
      } else if (this.activeName == "fourth") {
        this.queryMyCollectCircle();
      } else if (this.activeName == "fifth") {
        this.querytongzhi();
      } else if (this.activeName == "sixth") {
        this.queryspecial();
      }
    },
    // 重置
    resetfrom(formName) {
      // console.log(this.$refs, "重置");
      this.$refs[formName].resetFields();
      this.querySubject(0); //获取学科信息
      this.queryGrade(0); //获取年级信息
      if (this.activeName == "first") {
        this.queryMyCollectCourse();
      } else if (this.activeName == "second") {
        this.querySeriseCollectCourse();
      } else if (this.activeName == "third") {
        this.queryMyResource();
      } else if (this.activeName == "fourth") {
        this.queryMyCollectCircle();
      } else if (this.activeName == "fifth") {
        this.querytongzhi();
      } else if (this.activeName == "sixth") {
        this.queryspecial();
      }
    },
    // 查看帖子
    viewdata(row) {
      console.log(row, "查看帖子");
    },

    // 取消收藏
    async messageDeleteCollection(id, index) {
      const { data, status } = await this.$Http.messageDeleteCollection({
        serviceId: id,
        type: index,
      });
      if (status == 200) {
        // console.log(data, status, "取消收藏");
        this.$message.success("取消成功");
        // this.queryMyCollectCourse();
        // this.querySeriseCollectCourse();
        if (this.activeName == "first") {
          this.curpage = 1;
          this.queryMyCollectCourse();
        } else if (this.activeName == "second") {
          this.curpage = 1;
          this.querySeriseCollectCourse();
        } else if (this.activeName == "third") {
          this.curpage = 1;
          this.queryMyResource();
        } else if (this.activeName == "fourth") {
          this.curpage = 1;
          this.queryMyCollectCircle();
        } else if (this.activeName == "fifth") {
          this.curpage = 1;
          this.querytongzhi();
        } else if (this.activeName == "sixth") {
          this.curpage = 1;
          this.queryspecial();
        }
      }
    },
    //监听index
    computeTableIndex(index) {
      // console.log(index, this.queryInfo.pagenum, this.queryInfo.pagesize, 'e')
      return (this.curpage - 1) * this.pageSize + index + 1;
    },

    //tabs切换
    handleClick(tab, event) {
      // console.log(tab, event);
      this.$refs["resourceRef"].resetFields();
      if (tab.index == 0) {
        this.curpage = 1;
        this.queryMyCollectCourse();
      } else if (tab.index == 1) {
        this.curpage = 1;
        this.querySeriseCollectCourse();
      } else if (tab.index == 2) {
        this.curpage = 1;
        this.queryMyResource();
      } else if (this.activeName == "fourth") {
        this.curpage = 1;
        this.queryMyCollectCircle();
      } else if (this.activeName == "fifth") {
        this.curpage = 1;
        this.querytongzhi();
      } else if (this.activeName == "sixth") {
        this.curpage = 1;
        this.queryspecial();
      }
    },
    // 分页
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`, this.seriesId);
      this.curpage = val;
      if (this.activeName == "first") {
        this.queryMyCollectCourse();
      } else if (this.activeName == "second") {
        this.querySeriseCollectCourse();
      } else if (this.activeName == "third") {
        this.queryMyResource();
      } else if (this.activeName == "fourth") {
        this.queryMyCollectCircle();
      } else if (this.activeName == "fifth") {
        this.querytongzhi();
      } else if (this.activeName == "sixth") {
        this.queryspecial();
      }
    },
  },
  components: {
    card,
    cards,
    word,
    writing,
    words,
  },
};
</script>
<style lang="scss" scoped>
.collection {
  .col_center {
    // header{

    // }
    nav {
      background-color: #fff;
      padding: 15px;
      // .el-form-item{
      //   width: 230px;
      // }
      .el-input {
        width: 150px;
      }
      .el-select {
        width: 120px;
      }
      .nav_btns {
        display: flex;
        justify-content: flex-end;
      }
    }
    main {
      margin-top: 15px;
      padding: 10px 20px 0;
      background-color: #fff;
      .pcourse {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        // padding: 0 75px;
        // min-height: 620px;F
        .card {
          margin-left: 12px;
          margin-right: 12px;
        }
        .cards {
          margin-left: 12px;
          margin-right: 12px;
        }
        .word {
          margin-left: 12px;
          margin-right: 12px;
        }
        .card_footer {
          display: flex;
          justify-content: space-between;
          margin: 0px 12px 10px 5px;
          .card_one {
            display: inline-block;
            // width: 134px;
            // height: 18px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #5d6066;
            line-height: 30px;
            // vertical-align: middle;
          }
          .card_two {
            cursor: pointer;
            span {
              // width: 56px;
              // height: 18px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #1977f9;
              // line-height: 16px;
              vertical-align: middle;
            }
            .collcoticon {
              display: inline-block;
              width: 30px;
              height: 30px;
              vertical-align: middle;
              background: url("../../../../assets/icon/solidestar.png")
                no-repeat;
            }
          }
        }
      }
      ::v-deep .el-tabs__item {
        font-weight: 600;
      }
    }
    footer {
      background-color: #fff;
      display: flex;
      justify-content: center;
      padding: 24px 0;
    }
  }
}
</style>